import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperHeader } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('full-view',{attrs:{"title":_vm.$t('app.route.savedAddresses'),"fab":true,"forth-button":_vm.initAccount,"back-button":!_vm.initAccount},on:{"fab:click":_vm.newAddress},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [(_vm.initAccount)?_c(VStepper,{staticClass:"secondary",staticStyle:{"box-shadow":"none"},model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c(VStepperHeader,[_c(VStepperStep,{attrs:{"complete":true,"step":"1","color":"secondary darken-3"}},[_c('div',{staticClass:"v-stepper__label",staticStyle:{"color":"#4d977b"}},[_vm._v("Select Your Region")])]),_c(VDivider),_c(VStepperStep,{attrs:{"complete":false,"step":"2"}},[_vm._v(" Add Your Address ")])],1)],1):_vm._e()]},proxy:true}])},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,{staticClass:"overflow-y-auto px-0 pt-0",style:(_vm.getViewHeightStyle(36))},[(!_vm.getCurrentUser.activeAddress)?_c('p',{staticClass:"px-5 pt-3 body-2"},[_vm._v(" You haven't registered your address yet, please define one now: ")]):_vm._e(),_c(VList,{staticClass:"pt-0",attrs:{"two-line":""}},_vm._l((_vm.sortItemsBy(_vm.getAddresses, 'active')),function(address,i){return _c('div',{key:i},[_c(VListItem,{class:i % 2 === 0 ? 'white' : 'grey lighten-5',attrs:{"link":""}},[_c(VListItemAvatar,[_c(VIcon,{attrs:{"large":""}},[_vm._v("mdi-map-marker")])],1),_c(VListItemContent,{on:{"click":function () { return _vm.$router.push({
                    name: 'address',
                    params: { id: address.id },
                  }); }}},[_c(VListItemTitle,{domProps:{"textContent":_vm._s(address.title)}}),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.getAddressString(address)))])],1),_c(VListItemAction,[_c('div',{staticClass:"d-flex flex-space-between"},[_c(VBtn,{attrs:{"fab":"","icon":""},on:{"click":function($event){return _vm.setDetault(address)}}},[(address.active)?_c(VIcon,{attrs:{"large":"","color":"primary"}},[_vm._v("mdi-home-circle-outline")]):_vm._e(),(!address.active)?_c(VIcon,{attrs:{"large":""}},[_vm._v("mdi-circle-outline")]):_vm._e()],1),_c(VDivider,{staticClass:"mx-3",attrs:{"vertical":""}}),_c(VBtn,{attrs:{"fab":"","icon":""},on:{"click":function($event){return _vm.confirmDelete(address)}}},[_c(VIcon,{attrs:{"color":"red lighten-2","large":""}},[_vm._v("mdi-trash-can")])],1)],1)])],1),_c(VDivider)],1)}),0)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }